<template>
  <SimpleHeader />
  <main class="home">
    <div class="form-chooser">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <h1 style="text-align: center">Let’s Begin</h1>
          </div>
          <div class="form-group">
            <label for="selectedPage">I want to</label>
            <v-select
              v-model="selectedPage"
              placeholder="Select an option"
              :options="pages"
              :clearable="false"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import vSelect from 'vue-select';
import SimpleHeader from '@/components/layout/SimpleHeader.vue';

export default {
  name: 'HomeView',
  components: {
    SimpleHeader,
    vSelect,
  },
  data() {
    return {
      selectedPage: null,
      pages: [
        { label: 'Make a new booking', value: 'experience' },
        {
          label: 'Add-on extras to an existing booking',
          value: 'bookAddons',
        },
        { label: 'Move my existing booking', value: 'moveBooking' },
        // { label: 'Purchase Cauldron Credit', value: 'voucherCheckout' },
      ],
    };
  },
  watch: {
    selectedPage(val) {
      this.$router.push({ name: val.value });
    },
  },
  methods: {},
};
</script>
