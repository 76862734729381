<template>
  <router-view />
</template>

<script>
export default {
  mounted() {},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,500;1,600&family=Vesper+Libre:wght@700&display=swap");
@import "./assets/scss/main.scss";
@import "vue-select/dist/vue-select.css";
</style>
<style lang="scss">
h1 {
  margin-bottom: 30px;
}
</style>
