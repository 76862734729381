<template>
  <header id="header">
    <slot name="aboveLogo" />
    <div>
      <a href="/"><img :src="logo" class="logo" alt="logo" /></a>
    </div>
    <slot name="belowLogo" />
  </header>
</template>

<script>
export default {
  name: 'SimpleHeader',
  props: {
    logo: { type: String, default: '/images/logo-grey.png' },
  },
};
</script>

<style lang="scss">
header {
  text-align: center;
  padding: 60px 10px 26px;
  background-color: var(--color-background);
  color: white;

  padding: 60px 10px 84px;

  img {
    max-width: 277px;
  }
}
</style>
