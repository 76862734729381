<template>
  <div>Booking date and time</div>
  <pre>{{ $store.state.bookingDate }} {{ $store.state.bookingTime }}</pre>
  <div>Selected tickets</div>
  <pre>{{ pretty($store.getters.selectedTickets) }}</pre>
  <div>Selected ticket types</div>
  <pre>{{ pretty($store.getters.selectedTicketTypes) }}</pre>
  <div>Selected slot</div>
  <pre>{{ pretty($store.getters.selectedSlot) }}</pre>

  <div>Availability</div>
  <pre>{{ pretty($store.state.bookingAvailability) }}</pre>
  <div>Experience</div>
  <pre>{{ pretty($store.getters.experience) }}</pre>
</template>

<script>
export default {
  name: 'DebugView',
  methods: {
    pretty: (obj, indent = 2) => JSON.stringify(obj, null, indent),
  },
};
</script>
