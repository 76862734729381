import { createRouter, createWebHistory } from 'vue-router';
import DebugView from '@/views/DebugView.vue';
import { useVenueDataStore } from '@/stores/venue-data';
import HomeView from '../views/HomeView.vue';
import PageNotFound from '../views/PageNotFound.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/debug',
    name: 'debug',
    component: DebugView,
  },
  {
    path: '/book/:city/:experience/:venue/confirm-booking',
    name: 'confirm-booking',
    component() {
      return import('../views/ConfirmBooking.vue');
    },
  },
  {
    path: '/book/:city/:experience/:venue/booking-confirmed/:bookingReference',
    name: 'confirmation-page',
    component() {
      return import('../views/BookingConfirmed.vue');
    },
  },
  {
    path: '/book/:city?/:experience?/:venue?/:stepSlug?',
    name: 'experience',
    component() {
      return import('../views/Experience.vue');
    },
  },
  { path: '/404', component: PageNotFound, name: 'not-found' },
  { path: '/:pathMatch(.*)*', redirect: { name: 'not-found' } },
  {
    path: '/retrieveBooking',
    name: 'retrieveBooking',
    component() {
      return import('../components/ui/RetrieveBooking.vue');
    },
  },
  {
    path: '/bookAddons',
    name: 'bookAddonsLegacy',
    component() {
      return import('../views/BookAddons.vue');
    },
  },
  {
    path: '/book-addons',
    name: 'bookAddons',
    component() {
      return import('../views/BookAddons.vue');
    },
  },
  {
    path: '/move-booking/:stepSlug?',
    name: 'moveBooking',
    component() {
      return import('../views/MoveBooking.vue');
    },
  },
  {
    path: '/credit/:stepSlug?',
    name: 'voucherCheckout',
    component() {
      return import('../views/VoucherCheckout.vue');
    },
  },
  // For backwards compatibility
  {
    path: '/add',
    name: 'add',
    component() {
      return import('../views/BookAddons.vue');
    },
  },
  {
    path: '/amend',
    name: 'amend',
    component() {
      return import('../views/MoveBooking.vue');
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  // Preview mode
  const venueDataStore = useVenueDataStore();

  if (to.query.preview === 'true') {
    venueDataStore.setPreviewMode(true);
  } else if (to.query.preview === 'false') {
    venueDataStore.setPreviewMode(false);
  }
});

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

router.beforeEach((to) => {
  // irclick cookie
  if ('irclickid' in to.query) {
    let clickId = to.query.irclickid;
    if (clickId === 'null') {
      clickId = '';
    }
    setCookie('irclickid', clickId);
  }
});

export default router;
