export const API_URL = import.meta.env.VITE_API_URL;

// The keys used for identifying the different checkouts in checkoutSteps.js
export const CHECKOUT_NEW_BOOKING = 'new_booking';
export const CHECKOUT_MOVE_BOOKING = 'move_booking';
export const CHECKOUT_BOOK_ADDONS = 'book_addons';
export const CHECKOUT_BUY_VOUCHER = 'buy_voucher';

// A list of the valid checkout options
export const CHECKOUTS = [
  CHECKOUT_NEW_BOOKING,
  CHECKOUT_MOVE_BOOKING,
  CHECKOUT_BOOK_ADDONS,
  CHECKOUT_BUY_VOUCHER,
];
