import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import { SetupCalendar } from 'v-calendar';
import { createApp } from 'vue';
import { useVenueDataStore } from '@/stores/venue-data';
import App from './App.vue';
import { debugLog } from './debugLog';
import router from './router';

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

const app = createApp(App).use(router).use(SetupCalendar).use(pinia);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          'localhost',
          'thecauldron.io',
          '.herokuapp.com',
          /^\//,
        ],
      }),
    ],
    trackComponents: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
  });
}

const venueDataStore = useVenueDataStore();

if (!venueDataStore.venueDataLoaded) {
  // Before the first load, we have to make sure the venues are loaded.
  // Without this, navigation won't work properly

  venueDataStore.updateVenueData().then(() => {
    debugLog('mounting app after initial venue load');
    app.mount('#app');
  });
} else {
  // Load the app directly (then still update the venues, but in the background)
  app.mount('#app');
  debugLog('refreshing venues...');
  venueDataStore.updateVenueData();
}
