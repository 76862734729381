import { defineStore, acceptHMRUpdate } from 'pinia';
import { API_URL } from '@/constants';
import { debugLog } from '@/debugLog';

export const useVenueDataStore = defineStore('venueData', {
  state: () => ({
    previewMode: false,
    venueData: {},
    countries: {},
  }),
  getters: {
    venuesLoaded: (state) => {
      if (!Object.keys(state.venueData).length) {
        return false;
      }
      if (!Object.keys(state.countries).length) {
        return false;
      }
      return true;
    },
  },
  actions: {
    setPreviewMode(previewMode) {
      this.previewMode = previewMode;
    },
    async updateVenueData() {
      debugLog('updating venues...');

      let venuesUrl = `${API_URL}/venues`;
      if (this.previewMode) {
        venuesUrl += '?preview=true';
      }

      return Promise.all([
        fetch(venuesUrl)
          .then((res) => res.json())
          .then((data) => {
            debugLog('set venues');
            this.venueData = data;
          }),
        fetch(`${API_URL}/countries`)
          .then((res) => res.json())
          .then((data) => {
            debugLog('set countries');
            this.countries = data;
          }),
      ]);
    },
  },
  persist: true,
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVenueDataStore, import.meta.hot));
}
